import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, filter, map, Observable, tap, throwError } from 'rxjs';

import { AUTH_DATA_ACTIONS } from '../../../state/authentication/auth.actions';
import { LoginResponseModel } from '../../shared/models/login-response.model';
import { Memoized } from '@acetech-development/utilities/core';
import { Tokens } from '../../shared/models/auth-data.model';
import { AUTH_DATA_SELECTORS } from '../../../state/authentication/auth.selectors';
import { UserModel } from '../../shared/models/user.model';
import { TokenDecodeModel } from '../../shared/models/token-decode.model';
import { jwtDecode } from 'jwt-decode';
import { UserRolesModel } from '../../users/users.model';
import { APP_CONFIGURATION } from '../../shared/constants/routes.consts';
import { ENVIRONMENT } from '../../../../environments/environment';
import { USER_ROLES } from '../../shared/constants/user-roles.consts';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly store = inject(Store);

  @Memoized
  public get isAuthenticated$(): Observable<boolean> {
    return this.store.select(AUTH_DATA_SELECTORS.selectIsAuthenticated);
  }

  @Memoized
  public get userData$(): Observable<UserModel> {
    return this.store.select(AUTH_DATA_SELECTORS.selectUserData).pipe(
      filter(Boolean),
      map((userData) => userData),
    );
  }

  public login(username: string, password: string): Observable<LoginResponseModel> {
    const authData = { username, password };
    return this.http.post<LoginResponseModel>(
      ENVIRONMENT.registru.apiUrl + APP_CONFIGURATION.uris.authentication.mainUrl + APP_CONFIGURATION.uris.authentication.login,
      authData,
    );
  }

  public refreshToken(refreshToken: string): Observable<Tokens> {
    const headers = new HttpHeaders({
      'X-API-KEY': ENVIRONMENT.registru.apiKey,
    });

    return this.http
      .post<Tokens>(
        ENVIRONMENT.registru.apiUrl + APP_CONFIGURATION.uris.authentication.mainUrl + APP_CONFIGURATION.uris.authentication.refreshToken,
        { refreshToken },
        { headers },
      )
      .pipe(
        tap((tokens: Tokens) => {
          this.store.dispatch(
            AUTH_DATA_ACTIONS.RefreshTokens({
              token: tokens.token,
              refreshToken: tokens.refreshToken,
            }),
          );
        }),
        catchError((error: Error) => {
          this.store.dispatch(AUTH_DATA_ACTIONS.RefreshTokensFailed());
          return throwError(() => error);
        }),
      );
  }

  public logout(): void {
    this.store.dispatch(AUTH_DATA_ACTIONS.Logout());
  }

  public isTokenExpired(tokenExpiration: string): boolean {
    return new Date().getTime() >= new Date(JSON.parse(tokenExpiration) as Date).getTime();
  }

  public getUserData(authData: LoginResponseModel): UserModel {
    const tokenData: TokenDecodeModel = jwtDecode(authData.token);

    return {
      userName: tokenData.userName,
      exp: tokenData.exp,
      iat: tokenData.iat,
      id: tokenData.id,
      roles: this.getUserRoles(tokenData.roles),
      email: tokenData.email,
    };
  }

  public getUserRoles(roles: string[]): UserRolesModel {
    return {
      isSuperAdmin: roles.includes(USER_ROLES.SUPER_ADMIN),
      isAdmin: roles.includes(USER_ROLES.ADMIN),
      isOperator: roles.includes(USER_ROLES.OPERATOR),
    };
  }
}
